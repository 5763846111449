import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Progress,
  ListGroupItem,
  ListGroup,
  Table,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { AlignmentItem, ColorContrastItem } from "Mike/models/designAnalysis";

const DesignAnalysisResults = () => {
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const location = useLocation();
  const { state } = location;

  function toggleArrowTab(tab: any) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      if (tab >= 4 && tab <= 7) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }
  return (
    <div className="page-content">
      <Card style={{ backgroundColor: "unset" }}>
        <CardHeader>
          <h2 className="text-center mb-0">Results</h2>
        </CardHeader>
        <CardBody>
          <Form className="form-steps">
            <div className="step-arrow-nav mb-4">
              <Nav
                className="nav-pills custom-nav nav-justified"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    href="#"
                    id="steparrow-gen-info-tab"
                    className={classnames({
                      active: activeArrowTab === 4,
                      done: activeArrowTab <= 6 && activeArrowTab > 3,
                    })}
                    onClick={() => {
                      toggleArrowTab(4);
                    }}
                  >
                    Color Contrast Test
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    id="steparrow-gen-info-tab"
                    className={classnames({
                      active: activeArrowTab === 5,
                      done: activeArrowTab <= 6 && activeArrowTab > 4,
                    })}
                    onClick={() => {
                      toggleArrowTab(5);
                    }}
                  >
                    Layout Analysis
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    id="steparrow-gen-info-tab"
                    className={classnames({
                      active: activeArrowTab === 6,
                      done: activeArrowTab <= 6 && activeArrowTab > 5,
                    })}
                    onClick={() => {
                      toggleArrowTab(6);
                    }}
                  >
                    Accessibility Test
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <TabContent activeTab={activeArrowTab}>
              <TabPane id="steparrow-gen-info" tabId={4}>
                <Row>
                  <Col className="text-center mb-5">
                    <img
                      className="rounded-1"
                      src={state.design}
                      alt="design"
                      width={600}
                    ></img>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <p className="text-muted"></p>
                        <div className="table-responsive">
                          <Table className="table-striped table-nowrap align-middle mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Text</th>
                                <th scope="col">Text Color</th>
                                <th scope="col">Background Color</th>
                                <th scope="col">WCAG 2.1 AA</th>
                                <th scope="col">WCAG 2.1 AAA</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {state.colorContrast.map(
                                (item: ColorContrastItem, index: number) => (
                                  <tr key={index}>
                                    <td className="fw-medium">{item.text}</td>
                                    <td>
                                      <input
                                        type="color"
                                        className="form-control form-control-color"
                                        defaultValue={item.textColor}
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="color"
                                        className="form-control form-control-color"
                                        defaultValue={item.backgroundColor}
                                        disabled
                                      />
                                    </td>
                                    <td>
                                      {item.wcag_aa ? (
                                        <i className="ri-checkbox-circle-fill text-success fs-2 text ml-2"></i>
                                      ) : (
                                        <i className="ri-close-circle-fill text-danger fs-2 text ml-2"></i>
                                      )}
                                    </td>
                                    <td>
                                      {item.wcag_aaa ? (
                                        <i className="ri-checkbox-circle-fill text-success fs-2 text ml-2"></i>
                                      ) : (
                                        <i className="ri-close-circle-fill text-danger fs-2 text ml-2"></i>
                                      )}
                                    </td>
                                    <td>
                                      <h3
                                        className={`badge ${
                                          item.result
                                            ? "bg-success"
                                            : "bg-danger"
                                        }`}
                                      >
                                        {item.result ? "Pass" : "Fail"}
                                      </h3>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane id="steparrow-description-info" tabId={5}>
                <Row>
                  <Col className="text-center mb-5">
                    <img
                      className="rounded-1"
                      src={state.gridImage}
                      alt="design"
                      width={600}
                    ></img>
                  </Col>
                </Row>
                <Row>
                  <>
                    {state.alignment.map(
                      (item: AlignmentItem, index: number) => (
                        <Col key={index}>
                          <Card>
                            <CardBody>
                              <p className="text-muted"></p>
                              <Card>
                                <CardHeader>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                      <h6 className="card-title mb-0">
                                        {item.element}
                                      </h6>
                                    </div>
                                  </div>
                                </CardHeader>
                                <Collapse
                                  isOpen={true}
                                  className="card-body"
                                  id={`collapseexample${index}`}
                                >
                                  {item.improvements.map((suggestion, idx) => (
                                    <div className="d-flex mt-2" key={idx}>
                                      <div className="flex-shrink-0">
                                        <i className="ri-checkbox-circle-fill text-success"></i>
                                      </div>
                                      <div className="flex-grow-1 ms-2 text-muted">
                                        {suggestion}
                                      </div>
                                    </div>
                                  ))}
                                </Collapse>
                              </Card>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    )}
                  </>
                </Row>
              </TabPane>

              <TabPane id="pills-experience" tabId={6}>
                <Row>
                  {/* <Col lg={6}>
                    <Card>
                      <img className="card-img-top img-fluid" alt="Card cap" />
                      <CardBody>
                        <h4 className="card-title mb-2">Original</h4>
                        <p className="card-text text-muted">
                          {" "}
                          The image perceived by a human being with no issues
                        </p>
                        { state.ColorBlindnessTestItem.suggestions.map((suggestions:string, index:number) => (
                            <div className="d-flex mb-2">
                            <div className="flex-shrink-0">
                              <i className="ri-checkbox-circle-fill text-success"></i>
                            </div>
                            <div className="flex-grow-1 ms-2 text-muted">
                              {suggestions}
                            </div>
                          </div>
                        ))
                          
                        }
                      </CardBody>
                    </Card>
                  </Col> */}
                  <Col lg={6}>
                    <Card>
                      <img
                        className="card-img-top img-fluid"
                        src={state.colorBlindnessTest[0].datauri}
                        alt="Card cap"
                      />
                      <CardBody>
                        <h4 className="card-title mb-2">Tritanopia</h4>
                        <p className="card-text text-muted">
                          {" "}
                          Tritanopia is a type of color blindness characterized
                          by the inability to distinguish between blue and
                          yellow hues. People with tritanopia have difficulty
                          perceiving blue light, which results in blue appearing
                          greenish and yellow appearing as a pale pink or gray.
                        </p>
                          {state.colorBlindnessTest[0].suggestions.map(
                            (suggestion: string, index: number) => (
                                <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <i className="ri-checkbox-circle-fill text-success"></i>
                                </div>
                                <div className="flex-grow-1 ms-2 text-muted">
                                  {suggestion}
                                </div>
                              </div>
                            )
                          )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card>
                      <img
                        className="card-img-top img-fluid"
                        src={state.colorBlindnessTest[1].datauri}
                        alt="Card cap"
                      />
                      <CardBody>
                        <h4 className="card-title mb-2">Deuteranopia</h4>
                        <p className="card-text text-muted">
                          {" "}
                          Deuteranopia is a type of red-green color blindness
                          where individuals have difficulty distinguishing
                          between green and red hues.
                        </p>
                          {state.colorBlindnessTest[1].suggestions.map(
                            (suggestion: string, index: number) => (
                                <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <i className="ri-checkbox-circle-fill text-success"></i>
                                </div>
                                <div className="flex-grow-1 ms-2 text-muted">
                                  {suggestion}
                                </div>
                              </div>
                            )
                          )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card>
                      <img
                        className="card-img-top img-fluid"
                        src={state.colorBlindnessTest[2].datauri}
                        alt="Card cap"
                      />
                      <CardBody>
                        <h4 className="card-title mb-2">Protanopia</h4>
                        <p className="card-text text-muted">
                          Protanopia is a type of red-green color blindness
                          where individuals have difficulty perceiving red
                          light.
                        </p>

                        {state.colorBlindnessTest[2].suggestions.map(
                          (suggestion: string, index: number) => (
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <i className="ri-checkbox-circle-fill text-success"></i>
                              </div>
                              <div className="flex-grow-1 ms-2 text-muted">
                                {suggestion}
                              </div>
                            </div>
                          )
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default DesignAnalysisResults;
