import React, { useEffect, useRef, useState } from "react";
import grapesjs, { Editor } from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import exportPlugin from "grapesjs-plugin-export";
import basicBlockPlugin from "grapesjs-blocks-basic";
import gjsForms from "grapesjs-plugin-forms";
import gjsNav from "grapesjs-navbar";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { axiosInstance } from "Mike/utils/axiosConfig";
import axios from "axios";
interface GrapesJSProps {
  html: string;
  cssUrl: string;
  design: string;
  setHtml: React.Dispatch<React.SetStateAction<{
    design: string;
    code: string;
} | null>>;
  setShowGrapes: React.Dispatch<React.SetStateAction<boolean>>;
}

const GrapesJS: React.FC<GrapesJSProps> = ({ html, cssUrl,design,setHtml, setShowGrapes }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [editorInstance, setEditorInstance] = useState<Editor | null>(null);
  const [message, setMessage] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalHtml, setOriginalHtml] = useState<string>(html);
  const [updatedHtml, setUpdatedHtml] = useState<string>("");
  const navigate = useNavigate();

  function getScriptContentsFromHTML(htmlString: string) {
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML string into a document
    const doc = parser.parseFromString(htmlString, "text/html");

    // Select all script tags in the parsed document
    const scriptTags = doc.querySelectorAll("script");

    // Initialize an empty string to hold the concatenated contents
    let scriptContents = "";

    // Loop through all script tags and concatenate their contents
    scriptTags.forEach((script) => {
      scriptContents += script.innerHTML;
    });

    return scriptContents;
  }

  useEffect(() => {
    const editor = grapesjs.init({
      container: "#gjs",
      fromElement: true,
      height: "80vh",
      width: "auto",
      jsInHtml: true,
      canvas: {
        scripts: [
          "https://unpkg.com/tailwindcss-cdn@3.4.3/tailwindcss-with-all-plugins.js",
          "https://cdn.jsdelivr.net/npm/apexcharts",
        ],
      },
      storageManager: false,
      plugins: [exportPlugin, basicBlockPlugin, gjsForms, gjsNav],
    });

    setEditorInstance(editor);

    editor.BlockManager.add("my-block-id", {
      label: "My Block",
      content: '<div class="my-block">This is my block content</div>',
      category: "Custom",
    });

    editor.Commands.add("add-blocks", {
      run: (editor) => {
        editor.BlockManager.add("my-block-id", {
          label: "My Block",
          content: '<div class="my-block">This is my block content</div>',
          category: "Custom",
        });
      },
    });

    editor.runCommand("add-blocks");

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (editorInstance) {
      editorInstance.setComponents(html);
      const htmlScripts = getScriptContentsFromHTML(html);
      editorInstance.on("load", () => {
        const scriptContent = htmlScripts;
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = scriptContent;
        editorInstance.Canvas.getBody().appendChild(script);
      });

      // fetch(cssUrl)
      //   .then(response => {
      //     if (!response.ok) {
      //       throw new Error(`HTTP error! status: ${response.status}`);
      //     }
      //     return response.text();
      //   })
    }
  }, [html, cssUrl, editorInstance]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };
  console.log("Original HTML Content:", originalHtml);
  
  const extractHtmlContent = (html: string): string => {
    const match = html.match(/<!DOCTYPE html>[\s\S]*<\/html>/i);
    return match ? match[0] : "";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message) return;
    console.log("Handle Click Prompt Refined");

    const apiPayload = {
      prompt: `${message}\nHere is my previously generated HTML code:\n${html}\nMake the change as suggested and provide the complete updated HTML.`,
    };
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(
        "/WIREFRAME-EDITOR",
        apiPayload
      );
      if (response.data.status_code === 200) {
        const newPrompt = response.data.code;
        console.log("Response from Grape Js editor:", newPrompt);
        const updatedHtmlContent = extractHtmlContent(newPrompt);
        console.log("Updated HTML Content:", updatedHtmlContent);

        if (editorInstance) {
          editorInstance.setComponents(updatedHtmlContent);
        }
        setUpdatedHtml(updatedHtmlContent);
      } else {
        setApiError("No HTML content received from the API");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error in API call:", error.message);
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        }
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
    
  };
    // if (!message.trim()) {
    //   console.error("Message cannot be empty");
    //   return;
    // }
    // setIsLoading(true);
    // const payload = {
    //   prompt: `${message}\nHere is my previously generated HTML code:\n${html}\nMake the change as suggested and provide the complete updated HTML.`,
    // };

    // axiosInstance.post(
    //   "https://michelangelo-middleware-qa.azurewebsites.net/WIREFRAME-EDITOR",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   }
    // )
      // .then((response) => response.json())
      // .then((data) => {
      //   setIsLoading(false);
      //   if (data.code) {
      //     const updatedHtmlContent = extractHtmlContent(data.code);
      //     console.log("Updated HTML Content:", updatedHtmlContent);
      //     if (editorInstance) {
      //       editorInstance.setComponents(updatedHtmlContent);
      //     }
      //     setUpdatedHtml(updatedHtmlContent);
      //   } else {
      //     setApiError("No HTML content received from the API");
      //   }
      // })
      // .catch((error) => {
      //   setIsLoading(false);
      //   console.error("Error fetching new HTML:", error);
      //   setApiError(error.message || "An error occurred");
      // });


  const handleBackClick = () => {
    setShowGrapes(false);
  };

  function replaceBodyContent(A:string, B:string) {
    // Create a DOM parser to parse the HTML strings
    const parser = new DOMParser();

    // Parse the full HTML (A)
    const docA = parser.parseFromString(A, 'text/html');

    // Parse the body HTML (B)
    const docB = parser.parseFromString(B, 'text/html');

    // Replace the body of A with the body of B
    docA.body.innerHTML = docB.body.innerHTML;

    // Return the modified HTML as a string
    return docA.documentElement.outerHTML;
}



  const handleSave = () => {
    if(editorInstance)
      setHtml(prevState => ({
        design: design,
        code: replaceBodyContent(html,editorInstance.getHtml())
      }))
      console.log("The saced code:",editorInstance?.getHtml())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row className="my-1">
          <Col xs="auto">
            <i
              aria-label="back buttton"
              className="px-3 py-3 mx-2 ri-arrow-left-line rounded-pill shadow-lg back-btn"
              onClick={handleBackClick}
              style={{ cursor: "pointer" }}
            ></i>
          </Col>
        </Row>
        <Button onClick={handleSave}> Save</Button>
        <div id="gjs" ref={editorRef}></div>

        {/* {isLoading && (
          <p className="text-muted mb-0" style={{ paddingLeft: 30, paddingBottom: 10 }}>
            Please wait, we are crafting your message...
          </p>
        )} */}
        <div className="chat-input-section p-3 p-lg-4">
          <form id="chatinput-form" onSubmit={handleSubmit}>
            <Row className="g-0 align-items-center">
              <div className="col-auto">
                <div className="chat-input-links me-2"></div>
              </div>
              <div className="col">
                <div className="chat-input-feedback">
                  Please Enter a Message
                </div>
                <input
                  type="text"
                  value={message}
                  onChange={handleInputChange}
                  className="form-control chat-input bg-light border-light"
                  id="chat-input"
                  placeholder="Type your message..."
                />
              </div>
              <div className="col-auto">
                <div className="chat-input-links ms-2">
                  <div className="links-list-item">
                    <button
                      type="submit"
                      className="btn btn-success chat-send waves-effect waves-light"
                    >
                      <i className="ri-send-plane-2-fill align-bottom"></i>
                    </button>
                  </div>
                </div>
              </div>
            </Row>
          </form>
        </div>
        {apiError && (
          <div className="api-error mt-3">
            <h4>Error:</h4>
            <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
              {apiError}
            </pre>
          </div>
        )}
      </div>
      <Modal isOpen={isLoading} centered>
        <ModalHeader className="modal-title">Fixing your design</ModalHeader>
        <ModalBody className="modal-body text-center p-5">
          <div className="mt-4">
            <p className="text-muted mb-4">
              <div className="spinner-border text-primary" role="status"></div>
              <p className="">Please wait while we fix your design</p>
            </p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light">Cancel</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};


export default GrapesJS;
